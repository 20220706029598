<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              class="none-bottom" 
              type="search" 
              name="plantCd" 
              v-model="searchParam.plantCd"
              @datachange="plantChange"  />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 기간 -->
            <c-datepicker
              :range="true"
              label="LBLPERIOD"
              type="year"
              name="period"
              defaultStart="-2y"
              defaultEnd="0y"
              v-model="period"
              @datachange="plantChange"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="padding-top:5px !important;">
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="plantChange" />
          </div> -->
        </template>
      </c-search-box>
      <div class="row">
        <div class="col-12">
          <apexchart 
            class="accidentChart"
            ref="bountyOcuurChart" 
            height="350" 
            :options="bountyOcuurChart.chartOptions" 
            :series="bountyOcuurChart.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="yearOcuurChart" 
            height="350" 
            type="bar"
            :options="yearOcuurChart.chartOptions" 
            :series="yearOcuurChart.series"></apexchart>
        </div>
        <div class="col-4">
          <apexchart 
            class="accidentChart"
            ref="kindOcuurChart" 
            height="380" 
            :options="kindOcuurChart.chartOptions" 
            :series="kindOcuurChart.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="stateOcuurChart" 
            height="350" 
            :options="stateOcuurChart.chartOptions" 
            :series="stateOcuurChart.series"
            @dataPointSelection="stateSelectedBar"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="stateDetailOcuurChart" 
            height="350" 
            :options="stateDetailOcuurChart.chartOptions" 
            :series="stateDetailOcuurChart.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="largeFormOcuurChart" 
            height="350" 
            type="bar"
            :options="largeFormOcuurChart.chartOptions" 
            :series="largeFormOcuurChart.series"
            @dataPointSelection="formSelectedBar"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="smallFormOcuurChart" 
            height="350" 
            type="bar"
            :options="smallFormOcuurChart.chartOptions" 
            :series="smallFormOcuurChart.series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'accident-status',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
        iimUpOccurMasterId: '',
      },
      bountyOcuurChart: {
        chartOptions: {
          title: {
            text: '부서별 포상금 통계'
          },
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            stacked: false,
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
          },
          stroke: {
            width: [0, 4]
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              min: 0,
              title: {
                text: '포상금 합계',
              },
            }, 
            {
              opposite: true,
              title: {
                text: '건 수'
              }
            }
          ],
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val.toFixed(0)
              }
            },
          },
        },
        series: [
          {
            name: '포상금 합계',
            type: 'column',
            data: []
          },
          {
            name: '건 수',
            type: 'line',
            data: []
          }
        ],
        chartWidth: '80%',
      },
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '년도별 아차사고 발생건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      kindOcuurChart: {
        chartOptions: {
          title: {
            text: '종류별 아차사고 발생건수'
          },
          chart: {
            type: 'polarArea',
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: [],
          legend: {
            position: 'bottom',
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          fill: {
            type: 'gradient',
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return Number.isInteger(Number(val)) ? Number(val).toFixed(0) : Number(val).toFixed(1);
                }
              }
            }
          ],
        },
        series: [
          {
            name: '',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      stateOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '사고발생시 상태별 발생건수',
          },
          subtitle: {
            text: '※그래프 막대 클릭시 오른쪽의 그래프를 조회하실 수 있습니다.',
            style: {
              color: '#FF4560'
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },   
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: ['인지기능', '사고기능', '감정', '설비요인', '관리요인'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        // '인지기능', '사고기능', '감정', '설비요인', '관리요인'
        series: [
          {
            name: '',
            data: [],
          }
        ],
        chartWidth: '80%',
      },
      stateDetailOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            // 사고발생시 상태분류별 발생건수
            text: '',
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              fontFamily:  undefined,
              color:  '#263238'
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },   
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            annotations: [],
            categories: [],
          },
          yaxis: {
            // title: {
            //   text: '건'
            // }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '',
            data: [],
          }
        ],
        chartWidth: '80%',
      },
      largeFormOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '발생가능형태별(대분류) 발생건수'
          },
          subtitle: {
            text: '※그래프 막대 클릭시 오른쪽의 그래프를 조회하실 수 있습니다.',
            style: {
              color: '#FF4560'
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },   
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            annotations: [],
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '',
            data: [],
          }
        ],
        chartWidth: '80%',
      },
      smallFormOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            // 발생가능형태별(중분류) 발생건수
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },   
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // title: {
            //   text: '건'
            // }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '',
            data: [],
          }
        ],
        chartWidth: '80%',
      },
      period: [],
      options: [],
      selectProcessCd: '',
      editable: true,
      searchUrl: '',
      count: 0,
      yearUrl: '',
      kindUrl: '',
      stateUrl: '',
      bountyUrl: '',
      stateDetailUrl: '',
      largeFormUrl: '',
      smallFormUrl: '',
      codeGrpCd: '',
      upKey: '',
      nearCodeItems: ['IIM_NEARMISS_DISCERN', 'IIM_NEARMISS_FUNCTION', 'IIM_NEARMISS_FEEL', 'IIM_NEARMISS_EQUIP', 'IIM_NEARMISS_MANAGE'],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.bountyUrl = selectConfig.sop.iim.accident.near.status.bounty.url;
      this.yearUrl = selectConfig.sop.iim.accident.near.status.year.url;
      this.kindUrl = selectConfig.sop.iim.accident.near.status.kind.url;
      this.stateUrl = selectConfig.sop.iim.accident.near.status.state.url;
      this.stateDetailUrl = selectConfig.sop.iim.accident.near.status.statedetail.url;
      this.largeFormUrl = selectConfig.sop.iim.accident.near.status.form.large.url;
      this.smallFormUrl = selectConfig.sop.iim.accident.near.status.form.small.url;
      // code setting
      // list setting 
      this.plantChange();
    },
    plantChange() {
      if(this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.getBountyList();
      this.getYearList();
      this.getKindList();
      this.getStateList();
      this.getLargeFormList();

      this.$set(this.stateDetailOcuurChart.chartOptions.xaxis, 'categories', [])
      this.$set(this.stateDetailOcuurChart.series[0], 'name', '') 
      this.$set(this.stateDetailOcuurChart.series[0], 'data', []) 
      this.$refs['stateDetailOcuurChart'].refresh();
      this.stateDetailOcuurChart.chartWidth = '90%';

      this.$set(this.smallFormOcuurChart.chartOptions.xaxis, 'categories', [])
      this.$set(this.smallFormOcuurChart.series[0], 'name', '') 
      this.$set(this.smallFormOcuurChart.series[0], 'data', []) 
      this.$refs['smallFormOcuurChart'].refresh();
      this.smallFormOcuurChart.chartWidth = '90%';
    },
    setLabel() {
      this.bountyOcuurChart.chartOptions.title.text = this.$comm.getLangLabel('LBL00001564') // 부서별 포상금 통계
      this.bountyOcuurChart.chartOptions.yaxis[0].title.text = this.$comm.getLangLabel('LBL00001565') // 포상금 합계
      this.bountyOcuurChart.chartOptions.yaxis[1].title.text = this.$comm.getLangLabel('LBL00001566') // 건 수
      this.bountyOcuurChart.series = [
        {
          name: this.$comm.getLangLabel('LBL00001565'),
          type: 'column',
          data: []
        },
        {
          name: this.$comm.getLangLabel('LBL00001566'),
          type: 'line',
          data: []
        }
      ]

      this.yearOcuurChart.chartOptions.title.text = this.$comm.getLangLabel('LBL00001567') // 년도별 아차사고 발생건수

      this.kindOcuurChart.chartOptions.title.text = this.$comm.getLangLabel('LBL00001568') // 종류별 아차사고 발생건수

      this.stateOcuurChart.chartOptions.title.text = this.$comm.getLangLabel('LBL00001569') // 사고발생시 상태별 발생건수
      this.stateOcuurChart.chartOptions.subtitle.text = this.$comm.getLangMessage('MSG0000432') // ※그래프 막대 클릭시 오른쪽의 그래프를 조회하실 수 있습니다.
      this.stateOcuurChart.chartOptions.xaxis.categories = [this.$comm.getLangLabel('LBL00001530'), this.$comm.getLangLabel('LBL00001531'), 
      this.$comm.getLangLabel('LBL00001532'), this.$comm.getLangLabel('LBL00001533'), this.$comm.getLangLabel('LBL00001534')]
       // 인지기능', '사고기능', '감정', '설비요인', '관리요인'
    
      this.stateDetailOcuurChart.chartOptions.title.text = this.$comm.getLangLabel('LBL00001570') // 사고발생시 상태분류별 발생건수

      this.largeFormOcuurChart.chartOptions.title.text = this.$comm.getLangLabel('LBL00001571') // 발생가능형태별(대분류) 발생건수
      this.largeFormOcuurChart.chartOptions.subtitle.text = this.$comm.getLangMessage('MSG0000432') // ※그래프 막대 클릭시 오른쪽의 그래프를 조회하실 수 있습니다.

      this.smallFormOcuurChart.chartOptions.title.text = this.$comm.getLangLabel('LBL00001572') // 발생가능형태별(중분류) 발생건수


      this.$refs['bountyOcuurChart'].refresh();
      this.$refs['yearOcuurChart'].refresh();
      this.$refs['kindOcuurChart'].refresh();
      this.$refs['stateOcuurChart'].refresh();
      this.$refs['stateDetailOcuurChart'].refresh();
      this.$refs['largeFormOcuurChart'].refresh();
      this.$refs['smallFormOcuurChart'].refresh();
    },
    getBountyList() {
      this.$http.url = this.bountyUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        console.log(_result.data)
        this.$set(this.bountyOcuurChart.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'deptName'))
        this.$set(this.bountyOcuurChart.series[0], 'data', this.$_.map(_result.data, 'bountyCost'))
        this.$set(this.bountyOcuurChart.series[1], 'data', this.$_.map(_result.data, 'bountyCnt'))
        
        this.$refs['bountyOcuurChart'].refresh();
        this.bountyOcuurChart.chartWidth = '90%';
      })
    },
    getYearList() {
      this.$http.url = this.yearUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.yearOcuurChart.series = [];
        this.yearOcuurChart.chartOptions.xaxis.categories = [];
        // this.$_.forEach(_result.data, _item => {
        //   this.yearOcuurChart.chartOptions.xaxis.categories.push(_item.occurYear)
        // })
        this.$_.forEach(_result.data, _item => {
          // 년도에 따른 idx를 구하여 series.data의 위치 통일
          if (this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear) === -1) {
            this.yearOcuurChart.chartOptions.xaxis.categories.push(_item.occurYear);
          }

          let idx = this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear)
          if (this.$_.findIndex(this.yearOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
            let _data = [];
            this.$_.forEach(this.yearOcuurChart.chartOptions.xaxis.categories, _item => {
              _data.push(0)
            })

            this.yearOcuurChart.series.push({
              plantCd: _item.plantCd,
              name: _item.plantName,
              data: _data
            })
          }
          this.yearOcuurChart.series[this.$_.findIndex(this.yearOcuurChart.series, 
          { plantCd: _item.plantCd })].data[idx] = _item.yearCount
        })
        this.$refs['yearOcuurChart'].refresh();
        this.yearOcuurChart.chartWidth = '90%';
      },);
    },
    getKindList() {
      this.$http.url = this.kindUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.kindOcuurChart.chartOptions, 'labels', this.$_.map(_result.data, 'iimNearKindName'))
        this.$set(this.kindOcuurChart, 'series', this.$_.map(_result.data, 'kindCount')) 
        this.$nextTick(function () {
          this.$refs['kindOcuurChart'].refresh();
          this.kindOcuurChart.chartWidth = '90%';
        })
      },);
    },
    getStateList() {
      this.$http.url = this.stateUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        let data = _result.data

        if (data) {
          this.$set(this.stateOcuurChart.series[0], 'name', '') 
          this.$set(this.stateOcuurChart.series[0], 'data', [data.discernCount, data.functionCount, 
                                                              data.feelCount, data.equipCount, data.manageCount])
        } else {
          this.$set(this.stateOcuurChart.series[0], 'data', [0, 0, 0, 0, 0]);
        }
        this.$refs['stateOcuurChart'].refresh();
        this.stateOcuurChart.chartWidth = '90%';
      },);
    },
    getStateDetailList() {
      if (!this.codeGrpCd) return;
      this.$http.url = this.stateDetailUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        startYmd: this.searchParam.startYmd,
        endYmd: this.searchParam.endYmd,
        codeGrpCd: this.codeGrpCd, 
      };
      this.$http.request((_result) => {
        this.$set(this.stateDetailOcuurChart.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'stateName'))
        this.$set(this.stateDetailOcuurChart.series[0], 'name', '') 
        this.$set(this.stateDetailOcuurChart.series[0], 'data', this.$_.map(_result.data, 'stateCount')) 
        this.$refs['stateDetailOcuurChart'].refresh();
        this.stateDetailOcuurChart.chartWidth = '90%';
      },);
    },
    getLargeFormList() {
      this.$http.url = this.largeFormUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.largeFormOcuurChart.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'occurName'))
        this.$set(this.largeFormOcuurChart.chartOptions.xaxis, 'annotations', this.$_.map(_result.data, 'iimOccurMasterId'))
        this.$set(this.largeFormOcuurChart.series[0], 'name', '') 
        this.$set(this.largeFormOcuurChart.series[0], 'data', this.$_.map(_result.data, 'largeCount')) 
        this.$refs['largeFormOcuurChart'].refresh();
        this.largeFormOcuurChart.chartWidth = '90%';
      },);
    },
    getSmallFormList() {
      if (!this.upKey) return;
      this.$http.url = this.smallFormUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        startYmd: this.searchParam.startYmd,
        endYmd: this.searchParam.endYmd,
        iimUpOccurMasterId: this.upKey, 
      };
      this.$http.request((_result) => {
        this.$set(this.smallFormOcuurChart.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'occurName'))
        this.$set(this.smallFormOcuurChart.series[0], 'name', '') 
        this.$set(this.smallFormOcuurChart.series[0], 'data', this.$_.map(_result.data, 'smallCount')) 
        this.$refs['smallFormOcuurChart'].refresh();
        this.smallFormOcuurChart.chartWidth = '90%';
      },);
    },
    formSelectedBar(e, chart, opts) {
      this.upKey = opts.w.config.xaxis.annotations[opts.dataPointIndex]
      this.getSmallFormList();
    },
    stateSelectedBar(e, chart, opts) {
      this.codeGrpCd = this.nearCodeItems[opts.dataPointIndex]
      this.getStateDetailList();
    }
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important

// .el-cascader-node
//   padding: 0 30px 0 5px !important
// .el-cascader-menu
//   min-width: auto
// .el-cascader-node__label
//   padding: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  // border-right: #dee2e6 solid 0 !important
  // border-bottom: #dee2e6 solid 1px !important
  padding: 0px !important
.widget-yearOcuurChart
  padding: 5px !important
.widget-yearOcuurChart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important

.accidentChart
  padding: 20px !important
  border: 1px solid #dee2e6
  border-top: 3px solid #dee2e6
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>